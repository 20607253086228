import React from 'react'
import styles from './Skills.module.css';
function Skills() {

  return (
    <div className={styles.mainSkillsClass}>
      <section className={styles.skills}>
        {/* <h2 className={styles.skillHeader}>SKILLS & TOOLS</h2> */}
        <h1>My Toolbox & Things I Can Do</h1>

        <div className={styles.skillsWrapper}>
            <div className={styles.item}>
              <img
                className={styles.iconCard}
                src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
                alt=""
                loading="lazy"
              />
              <span className={styles.caption}>React JS</span>
            </div>

            <div className={styles.item}>
              <img
                className={styles.iconCard}
                src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg"
                alt=""
                loading="lazy"

              />
              <span className={styles.caption}>Angular</span>
            </div>

            <div className={styles.item}>
              <img
                src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
                alt=""
                loading="lazy"
                className={styles.iconCard}
              />
              <span className={styles.caption}>javascript</span>
            </div>
            <div className={styles.item}>
              <img
                src="https://img.icons8.com/stickers/144/api-settings.png"
                alt=""
                loading="lazy"
                className={styles.iconCard}
              />
              <span className={styles.caption}>Restful APIs</span>
            </div>
            <div className={styles.item}>
              <img
                src="https://img.icons8.com/color/96/git.png"
                alt=""
                loading="lazy"
                className={styles.iconCard}
              />
              <span className={styles.caption}>Git</span>
            </div>
      

            <div className={styles.item}>
            <img
              src="https://img.icons8.com/color/96/amazon-web-services.png"
              alt=""
              loading="lazy"
              className={styles.iconCard}
            />
             <span className={styles.caption}>AWS</span>
            </div>

            <div className={styles.item}>
            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg"
              alt=""
              loading="lazy"
              className={styles.iconCard}
            />
             <span className={styles.caption}>Redux</span>
            </div>

            <div className={styles.item}>
            <img
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-original.svg"
              alt=""
              loading="lazy"
              className={styles.iconCard}
            />
             <span className={styles.caption}>Springboot</span>
            </div>

            <div className={styles.item}>
            <img
              src="https://img.icons8.com/color/144/html-5--v1.png"
              alt=""
              loading="lazy"
              className={styles.iconCard}
            />
             <span className={styles.caption}>HTML</span>
            </div>

            <div className={styles.item}>
            <img
              src="https://img.icons8.com/stickers/96/css3.png"
              alt=""
              loading="lazy"
              className={styles.iconCard}
            />
             <span className={styles.caption}>CSS</span>
            </div>
          </div>
       
      </section>
    </div>
  )
}

export default Skills