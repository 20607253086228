import React from 'react'
import styles from './About.module.css';
import { useSpring, animated } from '@react-spring/web';
import Image from "../assets/images/me3.png";
import Animation from '../components/Animation';
import Social from '../components/Social';
// import { Spring } from 'react-spring/renderprops'

function About() {
  const springs = useSpring({
    from: { x: 0 },
    to: { x: 100 },
  })
  return (

    <div className={styles.aboutPage}>
      <section className={styles.about}>

        <div className={styles.aboutIntro}>
          <p>Hi, I am  Vanshika, a Front-end Developer <span></span></p>
  
        </div>

           <img
          src={Image}
          alt="jane-doe"
          loading="lazy"
          className={styles.imgageStye}
        />
      </section>
      <section className={styles.moreAbout}>
        {/* <h2>ABOUT ME</h2> */}
        <p>
          Hey! I'm a frontend developer living in Halifax. For the past three years,
          I've been working at IBM, where I make websites and apps look awesome and work smoothly. </p>
        <p>
          Right now, I'm learning Spring Boot to add more cool stuff to my skill set. Outside of work, I love boxing – it's my way of staying fit and having fun.
        </p>
        <p>
          I also enjoy taking long walks, especially when I need a break and some fresh air.
          Always up for a chat about tech, design, or anything interesting! Let's connect and make something cool together!
        </p>
      </section>
      <Social/>
    </div>

  )
}

export default About