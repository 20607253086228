import React from 'react'
import styles from './Projects.module.css';
import stayTipsy from "../assets/images/stayTipsy.png";
import vedSearch from "../assets/images/vedSearch.png";
import matcha from "../assets/images/matcha.png";
import coffee from  "../assets/images/coffee.png";
import ButtonComponent from '../components/Button';
// import WaitCheck from "../assets/images/WaitCheckDemo.mov";
function Projects() {
 
  return (
    <div>
      <section className={styles.projects}>
        <h1>A Selection Of Stuff I've Built</h1>

        <div className={styles.projectsContainer}>

          <div className={styles.projectCard}>
            <div className={styles.projectImage}>
                <img
                src={coffee}
                alt="coffee"
                loading="lazy"
                className={styles.coffee}
              />
             
            </div>
            <div className={styles.textCard}>
              <h3 className={styles.projectTitle}>Coffee Cafe</h3>
              <p className={styles.projectDetails}>
              Built a coffee website using React, Redux, Redux-Saga, React Query, and Firebase. Users can search for different coffee types, add their favorites to a list, and manage items in a shopping cart. The site uses Firebase for storing data and user management, while Redux and React Query handle state and data fetching efficiently.
              </p>
            
            <ButtonComponent  targetUrl="https://coffee-shop-91e4d.firebaseapp.com/"/>
            </div>
          </div>

          <div className={styles.projectCard}>
            <div className={styles.projectImage}>
              <img
                src={vedSearch}
                alt="ved"
                loading="lazy"
                className={styles.projectPic}
              />
            </div>
            <div className={styles.textCard}>
              <h3 className={styles.projectTitle}>Ved Search</h3>
              <p className={styles.projectDetails}>
              The app offers a comprehensive exploration of Hindu Vedas, featuring all four types and their Shlokas. Users can search for specific texts, read detailed descriptions, share content on social media, and listen to audio versions. The English translations are meticulously crafted by esteemed professors from top Indian universities to preserve the essence of the original Sanskrit text.
              </p>
              
            <ButtonComponent targetUrl="https://vedsearch.org/"/>

            </div>
          </div>

          <div className={styles.projectCard}>
            <div className={styles.projectImage}>
              <img
                src={matcha}
                alt="ved"
                loading="lazy"
                className={styles.projectPic}
              />
            </div>
            <div className={styles.textCard}>
              <h3 className={styles.projectTitle}>Matcha</h3>
              <p className={styles.projectDetails}>
              The design showcases clean, modern aesthetics with soothing green tones that reflect the natural essence of Matcha. Key sections are visually distinct, employing CSS techniques like gradient backgrounds, hover effects, and well-structured layouts for an engaging user experience.
              </p>
              
            <ButtonComponent targetUrl="https://matcha-7f350.web.app/"/>

            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Projects